"use client";
import React, { useEffect, useMemo, useState } from "react";
import classNames from "classnames";
import Link from "next/link";
import { useAnimate } from "framer-motion";
import { useDrag } from "@use-gesture/react";
import { useRouter } from "next/navigation";
import { useMediaQuery } from "react-responsive";

const TabContext = React.createContext({ activeName: "" });

function TabContainer({
  activeName,
  tabs,
}: {
  activeName: string;
  tabs: { name: string; href: string; tabContent: React.ReactNode }[];
}) {
  return (
    <div className="flex flex-row items-stretch justify-stretch w-full fill-black">
      {tabs.map((tab) => (
        <TabItem
          key={tab.name}
          active={activeName === tab.name}
          name={tab.name}
          href={tab.href}
        >
          {tab.tabContent}
        </TabItem>
      ))}
    </div>
  );
}

function TabItem({
  children,
  href,
  active,
}: {
  name: string;
  href: string;
  active: boolean;
  children: React.ReactNode;
}) {
  return (
    <Link
      href={href}
      className={classNames(
        "p-[8px] w-full flex flex-row justify-center items-center lg:hidden",
        {
          "border-b-4 border-b-primary": active,
          "border-b border-b-[#E1DED9]": !active,
          "pb-[11px]": !active,
          "text-[#292725]": active,
          "text-[#706E6D]": !active,
          "fill-primary": active,
          "fill-secondary": !active,
        }
      )}
    >
      {children}
    </Link>
  );
}

export function Tabs({
  activeName,
  children,
}: {
  activeName: string;
  children: React.ReactNode;
}) {
  const [currentTab, setCurrentTab] = useState<string>();
  const [scope, animate] = useAnimate();
  const isMobile = useMediaQuery({
    query: "(max-width: 1023px)",
  });
  const tabs = useMemo(() => {
    const rtn: { name: string; href: string; tabContent: React.ReactNode }[] =
      [];
    React.Children.forEach(children, (child) => {
      if (React.isValidElement(child)) {
        rtn.push({
          name: child.props.name,
          href: child.props.href,
          tabContent: child.props.tabContent,
        });
      }
    });

    return rtn;
  }, [activeName]);

  useEffect(() => {
    // only animate on mobile
    if (!isMobile) {
      return;
    }
    if (!currentTab) {
      setCurrentTab(activeName);
    } else if (currentTab !== activeName) {
      const animation = async () => {
        const enter = animate(
          `#tab-${activeName}`,
          {
            opacity: 1,
            x: 0,
          },
          { duration: 0.3 }
        );
        const exit = animate(
          `#tab-${currentTab}`,
          {
            opacity: 0,
            x: -100,
          },
          { duration: 0.3 }
        );
        setCurrentTab(activeName);

        await Promise.allSettled([enter, exit]);
        // await animate(
        //   `#tab-${currentTab}`,
        //   {
        //     x: 0,
        //   },
        //   { duration: 0.3 }
        // );
      };
      animation();
    }
  }, [activeName, currentTab, setCurrentTab]);

  return (
    <TabContext.Provider value={{ activeName }}>
      <div>
        <TabContainer activeName={activeName} tabs={tabs} />
        <div className="flex flex-row items-start" ref={scope}>
          {children}
        </div>
      </div>
    </TabContext.Provider>
  );
}

export function Tab({
  name,
  children,
}: {
  name: string;
  href: string;
  tabContent: React.ReactNode;
  children: React.ReactNode;
}) {
  const { activeName } = React.useContext(TabContext);
  const isActive = activeName === name;
  return (
    <div
      id={`tab-${name}`}
      className={classNames(
        "p-4 w-full lg:w-1/3",
        { hidden: !isActive },
        "lg:flex lg:flex-col"
      )}
    >
      {children}
    </div>
  );
}
