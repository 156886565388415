import slugify from "slugify";

export function getInvitationLink(
  inviteCode: string,
  sponsorName: string,
  recipientName: string
) {
  const pin = generatePin(inviteCode.substring(inviteCode.length - 4));
  const accessCode = `${slugify(recipientName, { replacement: "" })}-${pin}`;
  const base64Code = btoa(`${inviteCode}|${accessCode}|${sponsorName}`);
  return `${
    process.env["NEXT_PUBLIC_SITE_URL"]
  }invitation?i=${encodeURIComponent(base64Code)}`;
}

export function getAccessInfo(
  inviteCode: string,
  sponsorName: string,
  recipientName: string
) {
  const pin = generatePin(inviteCode.substring(inviteCode.length - 4));
  const accessCode = `${slugify(recipientName, { replacement: "" })}-${pin}`;
  return `
Sponsor: ${sponsorName}

  Expires in 7 days. Happy shopping! 🎂`;
}

// generate a four digit pin number from the invite code
function generatePin(seed: string) {
  const pin = seed
    .split("")
    .map((c) => c.charCodeAt(0))
    .reduce((acc, c) => acc + c, 0)
    .toString()
    .slice(-4);
  return pin.padStart(4, "0");
}

export function getMultiUseInvitationLink(
  inviteCode: string,
  sponsorName: string
) {
  const base64Code = btoa(`${inviteCode}|${inviteCode}|${sponsorName}`);
  return `${process.env.NEXT_PUBLIC_SITE_URL}invitation?i=${encodeURIComponent(
    base64Code
  )}`;
}

export function getMultiUseAccessInfo(inviteCode: string, sponsorName: string) {
  return `
Sponsor: ${sponsorName}

Expires in 7 days. Happy shopping! 🎂`;
}
